/** @jsx jsx */
import { jsx } from 'theme-ui';
import _ from 'lodash';

import ImageMarquee from '~/components/Generic/Marquee/Image';

const CommunitySection = ({ section }) => {
  const { marqueeImages, heading } = section;
  const imageNodes = _.map(marqueeImages, (marqueeImage) => ({
    altText: marqueeImage.altText,
    file: marqueeImage.image.file,
    description: marqueeImage.image.description ?? marqueeImage.image.title,
    link: marqueeImage.link,
    gatsbyImageData: marqueeImage.image.gatsbyImageData,
  }));

  return <ImageMarquee images={imageNodes} heading={heading} spinner offsetImages />;
};

export default CommunitySection;
