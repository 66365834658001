/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import TamponTest from '~/components/Pages/TamponTest';

const SyncPeriodsNotDataPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Metadata title="SyncPeriodsNotData" description="" />
      <TamponTest page={page} />
    </Layout>
  );
};

export default SyncPeriodsNotDataPage;

export const query = graphql`
  query SyncPeriodsNotDataQuery {
    page: contentfulPage(slug: { eq: "syncperiodsnotdata" }) {
      ...GenericPageFragment
    }
  }
`;
