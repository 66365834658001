/** @jsx jsx */
import React from 'react';
import { jsx, Container, Text, Box, Flex } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import Spacer from '~/components/Generic/Layout/Spacer';
import RichTextRenderer from '../../Content/RichText';
import CollapsiblePanelChevron from '~/components/Generic/CollapsiblePanel/ChevronFaq';
import ShopSplashTextMarquee from '~/components/Generic/ShopSplashTextMarquee';
import CommunitySection from '../Splash/Sections/Community';
import IntroText from '~/assets/images/intro-text.svg';
import MakeChoice from '~/assets/images/make-choice.svg';
import SeeingRed from '~/assets/images/seeing-red.svg';
import WhatUCanDo from '~/assets/images/whatucando.svg';

const TamponTest = ({ page }) => {
  const { hero } = page;
  const red = page.sections.find((section) => section.title.toLowerCase().includes('red'));
  const tools = page.sections.find((section) => section.title.toLowerCase().includes('tools'));
  const what = page.sections.find((section) => section.title.toLowerCase().includes('what'));
  const flow = page.sections.find((section) => section.title.toLowerCase().includes('flow'));

  return (
    <>
      <ShopSplashTextMarquee
        textArr={['SHOP OUR FAVES']}
        symbol="•"
        backgroundColor="canary"
        fontWeight="bold"
        linkTo="/shop"
      />
      <Container
        sx={{
          px: ['1.5rem', '3rem', '4rem'],
          width: '100vw',
          maxWidth: '115rem',
          mt: '4rem',
          img: {
            objectFit: 'contain',
            width: '100%',
          },
          a: {
            textTransform: 'lowercase',
            letterSpacing: 'inherit',
          },
          '#mobileColumn': {
            flexDirection: ['column', 'row', 'row'],
            alignContent: 'space-between',
            mt: '2rem',
            p: {
              ml: [0, 0, '2rem'],
              mt: ['2rem', '2rem', 0],
            },
            img: {
              maxHeight: '30rem',
              width: ['100%', '100%', '50%'],
            },
          },
        }}
      >
        {/* Hero Section */}
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', px: [0, 0, '4rem'] }}>
          <IntroText sx={{ width: '120%' }} />
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <Flex
            sx={{
              width: '100%',
              aspectRatio: '16/9',
              iframe: {
                border: 'none',
              },
            }}
          >
            <iframe
              width="100%"
              src="https://www.youtube.com/embed/D4yyrn9mLJ8?autoplay=1&mute=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </Flex>
          <Spacer height={['3rem', '3.5rem', '4rem']} />
        </Flex>

        {/* Seeing Red Section */}
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            px: [0, 0, '4rem'],
          }}
        >
          <Text sx={{ fontSize: ['1.4rem', '1.6rem', '2rem'] }}>
            <RichTextRenderer richText={red.subheading} />
          </Text>
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <SeeingRed sx={{ width: '110%' }} />
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <Text sx={{ fontSize: ['1.4rem', '1.6rem', '2rem'] }}>
            <RichTextRenderer richText={red.copy} />
          </Text>
        </Flex>

        <Spacer height={['3rem', '3.5rem', '4rem']} />
        <GatsbyImage image={red.images[1].gatsbyImageData} alt="tampon" />
        <Spacer height={['3rem', '3.5rem', '4rem']} />

        {/* tools section */}
        <Flex
          sx={{
            alignItems: 'center',
            px: [0, 0, '4rem'],
            flexDirection: 'column',
          }}
        >
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <MakeChoice sx={{ width: '110%' }} />
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <Box sx={{ px: [0, 0, '4rem'] }}>
            <Flex
              sx={{
                mb: '2rem',
                mt: '2rem',
                flexDirection: ['column', 'row', 'row'],
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <GatsbyImage
                image={tools.images[1].gatsbyImageData}
                alt="tampon-mascot"
                imgStyle={{ width: '100%' }}
              />
              <Text
                sx={{
                  fontSize: ['1.4rem', '1.6rem', '2rem'],
                  ml: [0, 0, '4rem'],
                  a: { textTransform: 'capitalize' },
                }}
              >
                <RichTextRenderer richText={tools.subheading} />
              </Text>
            </Flex>
            {tools.detailSections.map((detailSection) => (
              <CollapsiblePanelChevron
                title={detailSection.title}
                titleColor="alizarinCrimson"
                mobileColumn
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: detailSection.body.childMarkdownRemark.html,
                  }}
                  sx={{
                    fontSize: ['1.4rem', '1.6rem', '2rem'],
                    a: { textDecoration: 'underline' },
                    img: {
                      width: ['100%', '100%', '50%'],
                      maxHeight: '20rem',
                    },
                  }}
                />
              </CollapsiblePanelChevron>
            ))}
          </Box>
        </Flex>

        {/* what you can do section  */}
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', px: [0, 0, '4rem'] }}>
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <WhatUCanDo sx={{ width: '130%' }} />
          <Spacer height={['3rem', '3.5rem', '4rem']} />
          <Box
            sx={{
              px: [0, 0, '4rem'],
              width: '100%',
              p: {
                whiteSpace: 'pre-line',
              },
            }}
          >
            {what.detailSections.map((detailSection) => (
              <CollapsiblePanelChevron
                title={detailSection.title}
                titleColor="alizarinCrimson"
                mobileColumn
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: detailSection.body.childMarkdownRemark.html,
                  }}
                  sx={{
                    fontSize: ['1.4rem', '1.6rem', '2rem'],
                    a: { textDecoration: 'underline' },
                  }}
                />
              </CollapsiblePanelChevron>
            ))}
          </Box>
        </Flex>

        <Spacer height={['4rem', '4.5rem', '5rem']} />
        <Text sx={{ fontSize: '2rem', fontStyle: 'italic' }}>
          This is not a real August product and will not be available for sale. This fictional
          product was created to bring awareness to the issue of data privacy on the subject of
          abortions.
        </Text>
        <Spacer height={['4rem', '4.5rem', '5rem']} />
      </Container>

      {/* Flow with Us */}
      <Spacer height={['4rem', '4.5rem', '5rem']} />
      <CommunitySection section={flow} />
      <Spacer height={['4rem', '4.5rem', '5rem']} />
    </>
  );
};

export default TamponTest;
